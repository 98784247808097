import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import layout from '@/layout/index.vue'
import ReleaseData from '@/views/ReleaseData.vue'
import AppList from '@/views/AppList.vue'
import NovelData from '@/views/NovelData.vue'
import Login from '@/views/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
    children: [
      {
        path: '/login',
        component: Login
      },
    ]
  },
  {
    path: '/home',
    name: 'layout',
    component: layout,
    redirect: "/home",
    children: [
      {
        path: '/home',
        component: HomeView
      },
      {
        path: '/releaseData',
        component: ReleaseData
      },
      {
        path: '/appList',
        component: AppList
      },
      {
        path: '/novelData',
        component: NovelData
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
