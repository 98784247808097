
import { defineComponent, reactive, ref } from "vue";
import { login } from "@/api/api";
import { setToken } from "@/utils/auth";
import { useRouter } from "vue-router";
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import User from "@/components/svg/User.vue";
import Lock from "@/components/svg/lock.vue";

interface FormState {
  account: string;
  password: string;
}

export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
    User,
    Lock,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const formState = reactive<FormState>({
      account: "",
      password: "",
    });
    const onFinish = (values: any) => {
      console.log("Success:", values);
      loading.value = true;
      login(values).then((res) => {
        console.log(res);
        loading.value = false;
        if (res.code === "200") {
          setToken(res.data.token);
          router.push("/home");
        }
      });
    };

    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
    };
    return {
      formState,
      loading,
      onFinish,
      onFinishFailed,
    };
  },
});
