
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import * as echarts from "echarts";

interface EChartData {
  date: String;
  data: Number;
}

export default defineComponent({
  name: "HomeView",
  components: {},
  props: {
    chartId: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const option = ref();
    const chartDom = ref();
    const chartRef = ref(props.chartId);
    const spinning = ref(false);
    watch(
      () => props.dataSource,
      async (newVal, oldVal) => {
        spinning.value = true;
        var data_x = [];
        var data_y = [];
        newVal.forEach((val) => {
          const { date, data } = val as EChartData;
          data_x.push(date);
          if (
            props.chartId === "totalTopup" ||
            props.chartId === "addTopup" ||
            props.chartId === "estimatedAmountUsd" ||
            props.chartId === "adsAmountUsd" ||
            props.chartId === "profit" ||
            props.chartId === "roi"
          ) {
            data_y.push(Number(Number(data) / 100));
          } else {
            data_y.push(data);
          }
        });
        await nextTick(() => {
          spinning.value = false;
          dataHandle(data_x, data_y);
        });
      },
      { deep: true, immediate: true }
    );
    const dataHandle = (data_x: any, data_y: any) => {
      chartDom.value = document.getElementById(props.chartId);
      var myChart = echarts.init(chartDom.value);
      option.value = {
        xAxis: {
          type: "category",
          data: data_x,
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            show: false,
          },
          boundaryGap: false,
        },
        legend: {
          data: ["Email"],
        },
        yAxis: {
          type: "value",
          axisTick: { show: false },
          axisLine: { show: false },
          splitLine: {
            //多条横线
            show: false,
          },
          axisLabel: {
            show: false,
          },
          show: false,
        },
        grid: {
          left: "0",
          right: "0",
          containLabel: true,
        },
        series: [
          {
            data: data_y,
            type: "line",
            symbol: "none",
            lineStyle: { color: "rgb(0, 144, 254)" },
            itemStyle: { color: "rgb(0, 144, 254)" },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(218, 230, 253)",
                },
                {
                  offset: 1,
                  color: "rgb(255, 255, 255)",
                },
              ]),
            },
          },
        ],
        tooltip: {
          trigger: "axis",
        },
      };
      option.value && myChart.setOption(option.value);
    };
    onMounted(() => {});
    return {
      spinning,
    };
  },
});
