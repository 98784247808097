
import { defineComponent, onMounted, reactive, ref, createVNode } from "vue";
import {
  sysNovelConfigList,
  sysNovelConfigSave,
  sysNovelConfigDel,
} from "@/api/api";
import XDialog from "@/components/XDialog.vue";
import { message, Modal } from "ant-design-vue";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

interface FormState {
  name: String;
  packageName: String;
  isOther: Number;
  // url: String;
}
interface Params {
  name: String;
  packageName: String;
  url: String;
  id: Number;
}
export default {
  components: {
    XDialog,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
  },
  setup() {
    const appDataList = ref([]);
    const copyAppDataList = ref([]);
    const value = ref();
    const dislogVisible = ref(false);
    const isAdd = ref(true);
    const curItem = ref();
    const formState = reactive<FormState>({
      name: "",
      packageName: "",
      isOther: 0,
      // url: "",
    });
    const addLoading = ref(false);
    const onSearch = () => {
      const list = JSON.parse(JSON.stringify(copyAppDataList.value));
      var clist = list.filter((element) => {
        return element.name.indexOf(value.value) > -1;
      });
      appDataList.value = JSON.parse(JSON.stringify(clist));
    };
    const addClick = () => {
      isAdd.value = true;
      dislogVisible.value = true;
      for (const key in formState) {
        formState[key] = "";
      }
      formState.isOther = 0
    };
    const confirm = () => {
      var params: Params = {
        name: undefined,
        packageName: undefined,
        url: undefined,
        id: undefined,
      };
      for (const key in formState) {
        params[key] = formState[key];
      }
      if (!isAdd.value) {
        params.id = curItem.value.id;
      }
      params.url =
        "https://play.google.com/store/apps/details?id=" + params.packageName;
      // if (params.url.indexOf("http") === -1) {
      //   message.error("请填写完整的地址:（https://www.xxx.com）");
      //   return;
      // }
      addLoading.value = true;
      sysNovelConfigSave(params).then((res) => {
        addLoading.value = false;
        if (res.code === "200") {
          message.success(res.message);
          dislogVisible.value = false;
          getData();
        }
      });
    };
    const deleteClick = (item) => {
      Modal.confirm({
        title: "提示",
        icon: createVNode(ExclamationCircleOutlined),
        content: "确认要删除该应用？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          sysNovelConfigDel({ id: item.id }).then((res) => {
            if (res.code === "200") {
              message.success(res.message);
              getData();
            }
          });
        },
      });
    };
    const editClick = (item) => {
      curItem.value = item;
      isAdd.value = false;
      dislogVisible.value = true;
      for (const key in formState) {
        formState[key] = item[key];
      }
    };
    const getData = () => {
      sysNovelConfigList({}).then((res) => {
        appDataList.value = JSON.parse(JSON.stringify(res.data));
        copyAppDataList.value = JSON.parse(JSON.stringify(res.data));
      });
    };
    onMounted(() => {
      getData();
    });
    return {
      appDataList,
      value,
      dislogVisible,
      formState,
      isAdd,
      addLoading,
      onSearch,
      addClick,
      confirm,
      deleteClick,
      editClick,
    };
  },
};
