
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import EasyChart from "@/components/EasyChart.vue";
import XAtable from "@/components/XATable.vue";
import {
  sysNovelUserOrderHome,
  sysNovelUserOrderHomeListBottom,
} from "@/api/api";
import dayjs, { Dayjs } from "dayjs";
import { ts_to_time } from "@/utils/utils";

type RangeValue = [Dayjs, Dayjs];

interface SearchDate {
  beginTime: String;
  endTime: String;
}

export default defineComponent({
  name: "HomeView",
  components: {
    EasyChart,
    XAtable,
  },

  setup() {
    const dateFormat = ref("YYYY-MM-DD");
    const searchDate = ref<RangeValue>([dayjs(), dayjs()]);
    const columns = ref([
      {
        title: "序号",

        dataIndex: "index",

        key: "index",

        align: "center",

        customRender: (column) => {
          // return (pageNo.value - 1) * pageSize.value + column.index + 1;÷
          return column.index + 1;
        },
      },
      {
        title: "App",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "总充值",
        key: "amount",
        align: "right",
        isMinute: true,
        symbol: "$",
      },
      {
        title: "同比",
        key: "amountIncrease",
        slots: {
          customRender: "amountIncrease",
        },
      },
      {
        title: "新增充值",
        key: "newUserAmount",
        align: "right",
        isMinute: true,
        symbol: "$",
      },
      {
        title: "同比",
        key: "newUserAmountIncrease",
        slots: {
          customRender: "newUserAmountIncrease",
        },
      },
      {
        title: "注册",
        key: "regUser",
        align: "right",
        dataIndex: "regUser",
      },
      {
        title: "同比",
        key: "regUserIncrease",
        slots: {
          customRender: "regUserIncrease",
        },
      },
      {
        title: "占充值百分比",
        key: "amountAll",
        slots: {
          customRender: "amountAll",
        },
      },
    ]);
    const homeData = ref<any>({
      activeUser: 0,
      activeUser2: 0,
      activeUserIncrease: 0,
      adsAmountUsd: 0,
      adsAmountUsd2: 0,
      adsAmountUsdIncrease: 0,
      amount: 0,
      amount2: 0,
      amountIncrease: 0,
      estimatedAmountUsd: 0,
      estimatedAmountUsd2: 0,
      estimatedAmountUsdIncrease: 0,
      newOrderPay: 0,
      newOrderPay2: 0,
      newOrderPayIncrease: 0,
      newUserAmount: 0,
      newUserAmount2: 0,
      newUserAmountIncrease: 0,
      newUserPay: 0,
      newUserPay2: 0,
      newUserPayIncrease: 0,
      orderPay: 0,
      orderPay2: 0,
      orderPayIncrease: 0,
      profit: 0,
      profit2: 0,
      profitIncrease: 0,
      regUser: 0,
      regUser2: 0,
      regUserIncrease: 0,
      rio: 0,
      rio2: 0,
      rioIncrease: 0,
      userPay: 0,
      userPay2: 0,
      userPayIncrease: 0,
    });
    const tableData = ref();
    const spinning = ref(false);
    const pstTime = ref();
    const ranges = ref();
    const disabledDate = (current: Dayjs) => {
      var getTime = new Date().getTime();
      getTime = getTime - 16 * 3600 * 1000;
      return (
        dayjs(ts_to_time(getTime)) &&
        current > dayjs(ts_to_time(getTime)).startOf("day")
      );
    };
    const percentProgress = computed(() => (scope: any) => {
      var val = ((scope.amount / scope.amountAll) * 100).toFixed(2);
      return scope.amount && scope.amountAll ? val : 0;
    });

    const pickerChange = (val) => {
      searchDate.value = [dayjs(val[0]), dayjs(val[1])];
      getData();
    };
    const getData = () => {
      spinning.value = true;
      var params = <SearchDate>{};
      var getTime = new Date().getTime();
      getTime = getTime - 16 * 3600 * 1000;
      params.beginTime = dayjs(ts_to_time(getTime)).format(dateFormat.value);
      params.endTime = dayjs(ts_to_time(getTime)).format(dateFormat.value);
      if (searchDate.value && searchDate.value.length == 2) {
        params.beginTime = searchDate.value[0].format(dateFormat.value);
        params.endTime = searchDate.value[1].format(dateFormat.value);
      }
      sysNovelUserOrderHome(params).then((res) => {
        homeData.value = res.data;
        spinning.value = false;
      });

      sysNovelUserOrderHomeListBottom(params).then((res) => {
        tableData.value = res.data;
      });
    };
    const getTime = () => {
      var getTime = new Date().getTime();
      getTime = getTime - 16 * 3600 * 1000;
      pstTime.value = ts_to_time(getTime);
      searchDate.value = [
        dayjs(ts_to_time(getTime)),
        dayjs(ts_to_time(getTime)),
      ];
      var dayjs2 = dayjs(ts_to_time(getTime));
      ranges.value = {
        今天: [dayjs2, dayjs2] as RangeValue,
        昨天: [
          dayjs(
            new Date(dayjs2.year(), dayjs2.month(), dayjs2.get("date") - 1)
          ),
          dayjs(
            new Date(dayjs2.year(), dayjs2.month(), dayjs2.get("date") - 1)
          ),
        ] as RangeValue,
        过去7天: [
          dayjs(
            new Date(dayjs2.year(), dayjs2.month(), dayjs2.get("date") - 6)
          ),
          dayjs2,
        ] as RangeValue,
        过去30天: [
          dayjs(
            new Date(dayjs2.year(), dayjs2.month(), dayjs2.get("date") - 29)
          ),
          dayjs2,
        ] as RangeValue,
        过去90天: [
          dayjs(
            new Date(dayjs2.year(), dayjs2.month(), dayjs2.get("date") - 89)
          ),
          dayjs2,
        ] as RangeValue,
        过去180天: [
          dayjs(
            new Date(dayjs2.year(), dayjs2.month(), dayjs2.get("date") - 179)
          ),
          dayjs2,
        ] as RangeValue,
        上线至今: [dayjs2, dayjs2] as RangeValue,
      };
      setInterval(() => {
        var getTime = new Date().getTime();
        getTime = getTime - 16 * 3600 * 1000;
        pstTime.value = ts_to_time(getTime);
      }, 1000);
    };
    onMounted(() => {
      getTime();
      getData();
    });

    return {
      searchDate,
      tableData,
      columns,
      ranges,
      pstTime,
      dateFormat,
      homeData,
      disabledDate,
      pickerChange,
      percentProgress,
      spinning,
    };
  },
});
