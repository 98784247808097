import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/kehu.png'


const _withScopeId = n => (_pushScopeId("data-v-1bae4910"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding-bottom":"20px"} }
const _hoisted_2 = { class: "searchFrom" }
const _hoisted_3 = { style: {"padding":"5px","float":"left"} }
const _hoisted_4 = { style: {"padding":"5px"} }
const _hoisted_5 = { style: {"margin-top":"20px"} }
const _hoisted_6 = { class: "apps" }
const _hoisted_7 = {
  key: 0,
  src: _imports_0,
  class: "kehu",
  alt: ""
}
const _hoisted_8 = { class: "edit" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "app-box-content" }
const _hoisted_12 = { class: "title" }
const _hoisted_13 = { class: "pack-name" }
const _hoisted_14 = { class: "jump" }
const _hoisted_15 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_x_dialog = _resolveComponent("x-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_input_search, {
          value: $setup.value,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
          placeholder: "请输入",
          "enter-button": "搜索",
          size: "default",
          onSearch: $setup.onSearch,
          style: {"width":"300px"}
        }, null, 8, ["value", "onSearch"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_button, {
          type: "primary",
          onClick: $setup.addClick
        }, {
          default: _withCtx(() => [
            _createTextVNode("添加")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.appDataList, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: "app-box row"
          }, [
            (item.isOther)
              ? (_openBlock(), _createElementBlock("img", _hoisted_7))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_a_button, {
                type: "primary",
                shape: "text",
                size: "small",
                onClick: ($event: any) => ($setup.editClick(item))
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_edit_outlined)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                shape: "text",
                size: "small",
                onClick: ($event: any) => ($setup.deleteClick(item))
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_delete_outlined)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            (item.state != -1)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: 
              item.state === 1
                ? item.image
                  ? item.image
                  : require('../assets/app.png')
                : require('../assets/noup.png')
            ,
                  alt: "",
                  class: "app-icon"
                }, null, 8, _hoisted_9))
              : (item.state === -1)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    src: require('../assets/down.png'),
                    alt: "",
                    class: "app-icon"
                  }, null, 8, _hoisted_10))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(item.name), 1),
              _createElementVNode("div", _hoisted_13, _toDisplayString(item.packageName), 1),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("a", {
                  href: item.url,
                  target: "_blank",
                  rel: "noopener noreferrer"
                }, "View on Google play", 8, _hoisted_15)
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_x_dialog, {
      title: $setup.isAdd ? '添加' : '编辑',
      visible: $setup.dislogVisible,
      onCancel: _cache[4] || (_cache[4] = ($event: any) => ($setup.dislogVisible = false)),
      onConfirm: $setup.confirm,
      loading: $setup.addLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, { model: $setup.formState }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "名称" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入名称",
                  value: $setup.formState.name,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "包名" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入包名",
                  value: $setup.formState.packageName,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.packageName) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "客户" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  name: "isOther",
                  value: $setup.formState.isOther,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.isOther) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, { value: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode("是")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, { value: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode("否")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["title", "visible", "onConfirm", "loading"])
  ]))
}