
import { defineComponent, onMounted, reactive, ref } from "vue";
import XAtable from "@/components/XATable.vue";
import { sysNovelUserOrderDataList, sysNovelConfigList } from "@/api/api";
import XDialog from "@/components/XDialog.vue";
import type { Rule } from "ant-design-vue/es/form";
import type { FormInstance } from "ant-design-vue";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { message } from "ant-design-vue";

type RangeValue = [Dayjs, Dayjs];

interface SearchFormState {
  appId: String | Number;
  time: RangeValue;
}

export default {
  components: {
    XAtable,
  },
  setup() {
    const searchFormState = reactive<SearchFormState>({
      appId: 0,
      time: [
        dayjs(
          new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 180)
        ),
        dayjs(),
      ],
    });
    const pageNo = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const appList = ref([]);
    const dateFormat = ref("YYYY-MM-DD");
    const loading = ref(false);
    const columns = reactive([
      {
        title: "应用",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "阅读用户数",
        key: "readUser",
        dataIndex: "readUser",
      },
      {
        title: "阅读记录数",
        key: "readCount",
        dataIndex: "readCount",
      },
      {
        title: "书币消耗",
        key: "coins",
        dataIndex: "coins",
      },
    ]);
    const tableData = ref([]);
    const formRef = ref<FormInstance>();

    const search = () => {
      pageNo.value = 1;
      getData();
    };
    const resetForm = () => {
      searchFormState.appId = 0;
      searchFormState.time = [
        dayjs(
          new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 180)
        ),
        dayjs(),
      ];
      pageNo.value = 1;
      getData();
    };
    const getAppList = () => {
      loading.value = true;
      sysNovelConfigList({}).then((res) => {
        appList.value = res.data;
        // searchFormState.appId = res.data[0].id;
        getData();
      });
    };
    const getData = () => {
      var parms = {
        beginTime:
          dayjs(searchFormState.time[0]).format(dateFormat.value) + " 00:00:00",
        endTime:
          dayjs(searchFormState.time[1]).format(dateFormat.value) + " 00:00:00",
        id: searchFormState.appId ? searchFormState.appId : null,
        pageNo: pageNo.value,
        pageSize: pageSize.value,
      };
      sysNovelUserOrderDataList(parms).then((res) => {
        loading.value = false;
        total.value = res.data.total;
        tableData.value = res.data.records;
      });
    };
    const pageNoChange = (val) => {
      pageNo.value = val;
      getData();
    };
    const onShowSizeChange = (current, size) => {
      pageSize.value = size;
      getData();
    };
    const selectChange = () => {
      pageNo.value = 1;
      getData();
    };
    onMounted(() => {
      getAppList();
    });
    return {
      searchFormState,
      columns,
      appList,
      tableData,
      pageNo,
      dateFormat,
      total,
      loading,
      search,
      resetForm,
      pageNoChange,
      onShowSizeChange,
      selectChange,
    };
  },
};
