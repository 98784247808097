import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43e6efe3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, { spinning: _ctx.spinning }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: _ctx.chartId,
        ref: _ctx.chartId,
        class: "chartcss"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["spinning"]))
}