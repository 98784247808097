import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/sysUser/login',
        method: 'post',
        data
    })
}
// 重置密码
export function resetPassword(data) {
    return request({
        url: '/sysUser/updatePass',
        method: 'post',
        data
    })
}
// 退出登录
export function loginOut(data: any) {
    return request({
        url: '/sysUser/logout',
        method: 'post',
        data
    })
}
// 投放数据列表
export function sysNovelUserOrderList(data: any) {
    return request({
        url: '/sysNovelUserOrder/queryList',
        method: 'get',
        params: data
    })
}
// 投放数据修改
export function sysNovelUserOrderSave(data: any) {
    return request({
        url: '/sysNovelUserOrder/save',
        method: 'post',
        data
    })
}
// 应用列表 
export function sysNovelConfigList(data: any) {
    return request({
        url: '/sysNovelConfig/queryListAll',
        method: 'get',
        params: data
    })
}
// 小说数据 
export function sysNovelUserOrderDataList(data: any) {
    return request({
        url: '/sysNovelUserOrder/data/queryList',
        method: 'get',
        params: data
    })
}
// 首页数据 
export function sysNovelUserOrderHome(data: any) {
    return request({
        url: '/sysNovelUserOrder/home/queryList',
        method: 'get',
        params: data
    })
}
// 首页数据列表 
export function sysNovelUserOrderHomeListBottom(data: any) {
    return request({
        url: '/sysNovelUserOrder/home/queryListBottom',
        method: 'get',
        params: data
    })
}
// 保存数据 
export function sysNovelConfigSave(data: any) {
    return request({
        url: '/sysNovelConfig/save',
        method: 'post',
        data
    })
}
// 删除数据 
export function sysNovelConfigDel(data: any) {
    return request({
        url: '/sysNovelConfig/del',
        method: 'post',
        data
    })
}
