
import { onMounted, reactive, ref } from "vue";
import XAtable from "@/components/XATable.vue";
import XDialog from "@/components/XDialog.vue";
import type { Rule } from "ant-design-vue/es/form";
import type { FormInstance } from "ant-design-vue";
import {
  sysNovelUserOrderList,
  sysNovelConfigList,
  sysNovelUserOrderSave,
} from "@/api/api";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { message } from "ant-design-vue";
import { ts_to_time } from "@/utils/utils";

type RangeValue = [Dayjs, Dayjs];

interface SearchFormState {
  appId: String;
  time: RangeValue;
}
interface FormState {
  adsAmountUsd: String;
  estimatedAmount: String;
  estimatedAmountUsd: String;
  rioFormat: String;
  amount: String;
  newUserAmount: String;
  profit: String;
  realAmount: String;
  vatAmount: String;
  rate: String;
}
interface CurRow {
  id: Number;
}
export default {
  components: {
    XAtable,
    XDialog,
  },
  computed: {
    bjdateHandle() {
      return (row: { date: string }) => {
        var replaceDate = row.date.replace(/-/g, "/");
        var getTime = new Date(replaceDate).getTime();
        getTime = getTime + 16 * 3600 * 1000;
        return `${ts_to_time(getTime).split(" ")[0]} 16:00:00`;
      };
    },
    bjdateHandle2() {
      return (row: { date: string }) => {
        var replaceDate = row.date.replace(/-/g, "/");
        var getTime = new Date(replaceDate).getTime();
        getTime = getTime + 16 * 3600 * 1000;
        var cgetTime = getTime + 8 * 3600 * 1000;
        return ` ${ts_to_time(cgetTime).split(" ")[0]} 15:59:59`;
      };
    },
  },
  setup() {
    const dateFormat = ref("YYYY-MM-DD");
    const formRef = ref<FormInstance>();
    const searchformRef = ref<FormInstance>();
    const appList = ref([]);
    const loading = ref(false);
    const pstTime = ref();
    const bjTime = ref();
    const cpstTime = ref();
    const formState = reactive<FormState>({
      adsAmountUsd: "",
      estimatedAmount: "",
      estimatedAmountUsd: "",
      rioFormat: "",
      amount: "",
      newUserAmount: "",
      profit: "",
      realAmount: "",
      vatAmount: "",
      rate: "",
    });
    const searchFormState = reactive<SearchFormState>({
      appId: "",
      time: [
        dayjs(
          new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 180)
        ),
        dayjs(
          new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 1)
        ),
      ],
    });
    const pageNo = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const curRow = ref<CurRow>({
      id: 0,
    });
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
    const handleFinish = (values) => {
      console.log(values, formState);
    };
    const handleFinishFailed = (errors) => {
      console.log(errors);
    };
    const resetForm = () => {
      searchFormState.appId = appList.value[0].id;
      searchFormState.time = [
        dayjs(
          new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 180)
        ),
        dayjs(
          new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 1)
        ),
      ];
      pageNo.value = 1;
      getData();
    };
    const handleValidate = (...args) => {
      console.log(args);
    };
    const columns = reactive([
      
      {
        title: "日期",
        key: "date",
        dataIndex: "date",
        width: 180,
        slots: {
          customRender: "date",
        },
      },
      {
        title: "北京时间",
        key: "bjdate",
        dataIndex: "bjdate",
        width: 200,
        maxWidth: 200,
        slots: {
          customRender: "bjdate",
        },
      },
      {
        title: "广告支出",
        key: "adsAmountUsd",
        dataIndex: "adsAmountUsd",
        slots: {
          customRender: "adsAmountUsd",
        },
        width: 100,
        symbol: "$",
      },
      {
        title: "充值收入",
        key: "amount",
        dataIndex: "amount",
        isMinute: true,
        width: 100,
        symbol: "$",
      },
      {
        title: "新充值",
        key: "newUserAmount",
        dataIndex: "newUserAmount",
        isMinute: true,
        width: 120,
        symbol: "$",
      },
      {
        title: "ROI",
        key: "rioFormat",
        dataIndex: "rioFormat",
        width: 90,
      },
      {
        title: "盈亏",
        key: "profit",
        dataIndex: "profit",
        isMinute: true,
        width: 90,
        symbol: "$",
      },
      {
        title: "谷歌总收入",
        key: "realAmount",
        dataIndex: "realAmount",
        width: 150,
        isMinute: true,
        symbol: "HK$",
      },
      {
        title: "谷歌扣税",
        key: "vatAmount",
        dataIndex: "vatAmount",
        isMinute: true,
        width: 150,
        symbol: "HK$",
      },
      {
        title: "谷歌税",
        key: "rate",
        dataIndex: "rate",
        width: 90,
        slots: {
          customRender: "rate",
        },
      },
      {
        title: "谷歌预估收入",
        key: "estimatedAmount",
        dataIndex: "estimatedAmount",
        isMinute: true,
        width: 150,
        symbol: "HK$",
      },
      {
        title: "预估收入美金",
        key: "estimatedAmountUsd",
        dataIndex: "estimatedAmountUsd",
        isMinute: true,
        width: 130,
        symbol: "$",
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        width: 100,
        slots: {
          customRender: "action",
        },
        fixed: "right",
      },
    ]);
    const dialogVisible = ref(false);
    const tableData = ref([]);
    const search = () => {
      pageNo.value = 1;
      getData();
    };
    const getAppList = () => {
      sysNovelConfigList({}).then((res) => {
        appList.value = res.data;
        searchFormState.appId = res.data[0].id;
        getData();
      });
    };
    const getData = () => {
      var parms = {
        beginTime:
          dayjs(searchFormState.time[0]).format(dateFormat.value) + " 00:00:00",
        endTime:
          dayjs(searchFormState.time[1]).format(dateFormat.value) + " 00:00:00",
        id: searchFormState.appId,
        pageNo: pageNo.value,
        pageSize: pageSize.value,
      };
      loading.value = true;
      sysNovelUserOrderList(parms).then((res) => {
        loading.value = false;
        total.value = res.data.total;
        tableData.value = res.data.records;
      });
    };
    const edit = (row) => {
      dialogVisible.value = true;
      curRow.value = row;
      for (const key in formState) {
        if (key === "rioFormat") {
          formState[key] = row[key];
        } else {
          formState[key] = "$" + row[key] / 100;
        }
        if (key === "rate") {
          formState.rate = (row[key] * 100).toFixed(2) + "%";
        }
        if (key === "adsAmountUsd") {
          formState[key] = String(row[key] / 100);
        }
        if (
          key === "realAmount" ||
          key === "vatAmount" ||
          key === "estimatedAmount"
        ) {
          formState[key] = "HK$" + row[key] / 100;
        }
      }
      var getTime = new Date(row.date).getTime();
      getTime = getTime + 16 * 3600 * 1000;
      var cgetTime = getTime + 8 * 3600 * 1000;
      pstTime.value = row.date;
      bjTime.value = ts_to_time(getTime);
    };
    const pageNoChange = (val) => {
      pageNo.value = val;
      getData();
    };
    const onShowSizeChange = (current, size) => {
      pageSize.value = size;
      getData();
    };
    const editOk = () => {
      var params = {};
      for (const key in formState) {
        params[key] = formState[key];
      }
      sysNovelUserOrderSave({
        adsAmountUsd: Number(formState.adsAmountUsd),
        id: curRow.value.id,
      }).then((res) => {
        if (res.code === "200") {
          message.success(res.message);
          dialogVisible.value = false;
          getData();
        }
      });
    };
    const selectChange = () => {
      pageNo.value = 1;
      getData();
    };
    const getTime = () => {
      var getTime = new Date().getTime();
      getTime = getTime - 16 * 3600 * 1000;
      cpstTime.value = ts_to_time(getTime);
      setInterval(() => {
        var getTime = new Date().getTime();
        getTime = getTime - 16 * 3600 * 1000;
        cpstTime.value = ts_to_time(getTime);
      }, 1000);
    };
    onMounted(() => {
      getTime();
      getAppList();
    });
    return {
      dateFormat,
      formState,
      columns,
      tableData,
      dialogVisible,
      searchFormState,
      formRef,
      layout,
      appList,
      pageNo,
      pageSize,
      total,
      loading,
      curRow,
      pstTime,
      bjTime,
      cpstTime,
      searchformRef,
      search,
      handleFinishFailed,
      handleFinish,
      resetForm,
      handleValidate,
      edit,
      pageNoChange,
      onShowSizeChange,
      editOk,
      selectChange,
    };
  },
};
